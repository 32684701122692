import React from 'react';

function ModalHeader(props) {
	let { cols1, cols2, cols3, cols4, cols5, cols6 } = props;
	let { title1, title2, title3, title4, title5, title6, links, functionPlus } = props;
	return (
		<div className="modalHeader">
			<div className="itemTitle col-sm-12 ">
				<div className={cols1}>
					<h3>{title1}</h3>
				</div>
				<div className={cols2}>
					<h3>{title2}</h3>
				</div>
				<div className={cols3}>
					<h3>{title3}</h3>
				</div>
				<div className={cols4}>
					<h3>{title4}</h3>
				</div>
				{cols5 ? (
					<div className={cols5}>
						<div className="text-right menuplusicon">
							<a href={links ? links : ''} onClick={functionPlus ? functionPlus : ''}>
								<img src={title5} className="App-plusicon" alt="plusicon" />
							</a>
						</div>
					</div>
				) : null}
				<div className={cols6}>
					<h3>{title6}</h3>
				</div>
			</div>
		</div>
	);
}

export default ModalHeader;
