import React from "react";

const SortComponent = (props) => {
  const handleClick = () => {
    props.dispatch(actions.openModal(modalType));
  };

  const sortFunc = (columnName, order) => {
    if (props.sortASC) {
      props.sortASC(columnName, order);
    }
  };

  const sortAsc = (event) => {
    sortFunc(props.columnName, "asc");

    event.stopPropagation();
  };

  const sortDsc = (event) => {
    event.stopPropagation();

    sortFunc(props.columnName, "dsc");
  };

  let { columnName, sortOrder = 'asc', sortedColumnName } = props;
  return (
    <div className="sort_container">
      <div className="icon_sort">
        <span
          onClick={sortAsc}
          id={props.id}
          className={
            columnName == sortedColumnName && sortOrder == "asc"
              ? "arrow-up sorted"
              : "arrow-up unsorted"
          }
        ></span>
      </div>
      <div className="icon_sort">
        <span
          onClick={sortDsc}
          id={props.id}
          className={
            columnName == sortedColumnName && sortOrder == "dsc"
              ? "arrow-bottom sorted"
              : "arrow-bottom unsorted"
          }
        ></span>
      </div>
    </div>
  );
};

export default SortComponent;
