import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import constants from '../../constants';
import Select from 'react-select';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import PropTypes from 'prop-types';

const recordOptions = [
	{ label: '10', value: '10' },
	{ label: '20', value: '20' },
	{ label: '30', value: '30' },
];

const PaginationComponent = (props) => {
	const sKey = props.sessionKey ? sessionStorage.getItem(props.sessionKey) : '';
	const [activePage, setActivePage] = useState(1);
	const [totalItemsCount, setTotalItemsCount] = useState(props.totalItemsCount);
	const [itemsCountPerPage, setItemsCountPerPage] = useState(sKey || 10);
	const [pageNotFound, setPageNotFound] = useState(false);
	const [gotoPageNumber, setGotoPageNumber] = useState('');

	useEffect(() => {
		if (props.currentPage != activePage) {
			setActivePage(props.currentPage);
		}
		if (props.resetPagination) {
			setActivePage(1);
			setTotalItemsCount(props.totalItemsCount);
		}
	}, [props.currentPage, props.resetPagination]);
	useEffect(() => {
		setTotalItemsCount(props.totalItemsCount);
	}),
		[props.totalItemsCount];

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		setPageNotFound(false);
		setGotoPageNumber('');

		if (props.gotoPage) {
			props.gotoPage(pageNumber, itemsCountPerPage);
		}
	};

	const onItemPerPageChange = (option) => {
		setItemsCountPerPage(option.value);
		setGotoPageNumber('');
		if (props.sessionKey) {
			sessionStorage.setItem(props.sessionKey, option.value.toString());
		}

		if (props.gotoPage) {
			props.gotoPage(1, option.value);
		}
	};

	const handleMouseEnter = (event) => {
		if (event.keyCode == 13) {
			const validPage =
				parseInt(event.target.value) <=
				Math.ceil(props.totalItemsCount / itemsCountPerPage);
			if (validPage && parseInt(event.target.value) >= 1) {
				setPageNotFound(false);
				if (props.gotoPage) {
					props.gotoPage(parseInt(event.target.value), itemsCountPerPage);
				}
			} else {
				setPageNotFound(true);
			}
		}
	};

	const handleChange = (event) => {
		setGotoPageNumber(event.target.value);
	};

	const totalOfCount =
		activePage * itemsCountPerPage >= totalItemsCount
			? totalItemsCount
			: activePage * itemsCountPerPage;
	return (
		<div className="paginationComponent">
			<div className="left-panel">
				<Pagination
					firstPageText={<i className="fa fa-angle-double-left fonticon" aria-hidden="true" />}
					lastPageText={<i className="fa fa-angle-double-right fonticon" aria-hidden="true" />}
					prevPageText={<i className="fa fa-angle-left fonticon" aria-hidden="true" />}
					nextPageText={<i className="fa fa-angle-right fonticon" aria-hidden="true" />}
					activePage={activePage}
					itemsCountPerPage={itemsCountPerPage}
					totalItemsCount={totalItemsCount}
					pageRangeDisplayed={3}
					onChange={handlePageChange}
					itemClass={'items'}
					itemClassFirst={'icons first'}
					itemClassPrev={'icons prev'}
					itemClassNext={'icons next'}
					itemClassLast={'icons last'}
				/>
			</div>
			<div className="right-panel">
				<div className="txt">
					<span className="recordText">
						{` Showing ${
							activePage == 1 ? 1 : (activePage - 1) * itemsCountPerPage
						} to ${totalOfCount} of ${totalItemsCount} records`}
					</span>
					<span className="pipe" />
					<span style={{ marginRight: 5 }}> Show </span>
					{/* <Select
						value={itemsCountPerPage}
						onChange={(value) => onItemPerPageChange(value)}
						isClearable={false}
						isSearchable={false}
						options={recordOptions}
						className={'pagination-records'}
					/> */}
					<CustomSelect
						value={{
							label: itemsCountPerPage?.toString(),
							value: itemsCountPerPage?.toString(),
						}}
						onChange={(value) => onItemPerPageChange(value)}
						clearable={false}
						searchable={false}
						options={recordOptions}
						menuPlacement={'top'}
						className={'pagination-records'}
					/>
					<span className="pipe" />
					<input
						className={pageNotFound ? 'error input-page' : 'input-page'}
						type="number"
						placeholder="Page number"
						value={gotoPageNumber}
						onChange={handleChange}
						onKeyDown={handleMouseEnter}
					/>
				</div>
			</div>
		</div>
	);
};

export default PaginationComponent;

PaginationComponent.propTypes = {
	sessionKey: PropTypes.string,
	resetPagination: PropTypes.bool,
	currentPage: PropTypes.number,
	gotoPage: PropTypes.func,
	totalItemsCount: PropTypes.number,
};
