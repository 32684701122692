import React from 'react';
import PropTypes from 'prop-types';

/**
 * THIS COMPONENT IS USED FOR DISPLAYING HYPERLINK FOR ANY DATA COUNTS.
 * IF COUNT < 1 IT WILL SHOW ONLY 0 WITHOUT HYPERLINK.
 */
const CountLink = ({ handleClick, count }) => {
	return count && count > 0 ? (
		<span onClick={handleClick} className="hyperlink">
			{count.toLocaleString(navigator.language)}
		</span>
	) : (
		<span style={{ color: '#c0c0c0' }}>0</span>
	);
};

CountLink.propTypes = {
	handleClick: PropTypes.func.isRequired,

	//COUNT OF COHORTS/STUDIES/DOCUMENTS/etc.
	count: PropTypes.any
};

export default CountLink;
