import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalComponent = (props) => (
	<div>
		{
			props.open && (
				<Modal
					show={true}
					onHide={() => props.handleClose()}
					//size="lg"
					centered
					dialogClassName={props.className?props.className:"setting-modals"}
					keyboard
					style={{ zIndex:'1200' }}
				>
					{
						props.header && 
						<Modal.Header>
						{props.header}
						</Modal.Header>
					}
					
					<Modal.Body>
						{props.content}
					</Modal.Body>
				</Modal>
			)
		}
	</div>
)

// ModalComponent.propTypes = {
// 	open: PropTypes.bool.isRequired,
// 	handleClose: PropTypes.func.isRequired,
// 	content: PropTypes.node.isRequired
// }

export default ModalComponent;