import React, { useState, useEffect, useRef } from 'react';
import IconSearch from '../../images/search_icon.svg'
function SearchComponent(props) {
	const { title } = props;

	const [searchText, setSearchText] = useState('');
	const [ seachPlaceHolder, setSeachPlaceHolder ] = useState(`Search for ${title}`);

	const onChange = (event) => {
		setSearchText(event.target.value);
		if (event.target.value == '') {
			props.searchFunction(event);
		}
	};

	useEffect(
		() => {
			setSearchText(props.searchText);
		},
		[ props.searchText ]
	);
	const ClearText = (event) => {
		setSearchText('');
		props.searchFunction(event);
	};

	const handleKeyDown = (event) => {
		if (event.keyCode == 13) {
			props.searchFunction(event);
		}
	};

	return (
		<div className="search-container">
			<img alt="" src={IconSearch} />
			<input
					type="text"
					className="search"
					placeholder={seachPlaceHolder}
				    onChange={onChange}
					onFocus={() => {
						setSeachPlaceHolder('');
					}}
					onBlur={() => {
						setSeachPlaceHolder(`Search for ${title}`);
					}}
					onKeyDown={handleKeyDown}
					value={searchText}
			/>
			{searchText && (
				<span className="searchCancel" onClick={ClearText}>
					X
				</span>
			)}
		</div>
	);
}

export default SearchComponent;
